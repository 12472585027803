import type { JSONSchema7 } from 'json-schema';
import { ROLE } from './types/shared.types';
export * from './lib/query/detection-query';
export * from './types/events.types';
export * from './types/shared.types';
export * from './types/verdict.types';
export type JSONSchema = {
  type?: JSONSchema7['type'];
  const?: string;
  $ref?: string;
  format?: string;
  anyOf?: Partial<JSONSchema>[];
  example?: any;
  definitions?: {
    [key: string]: JSONSchema;
  };
  allOf?: Partial<JSONSchema>[];
  title?: string;
  additionalProperties?: JSONSchema | boolean;
  items?: JSONSchema;
  description?: string;
  pattern?: string;
  minLength?: number;
  maxLength?: number;
  nullable?: boolean;
  enum?: string[];
  oneOf?: Partial<JSONSchema>[];
  required?: string[];
  properties?: {
    [key: string]: JSONSchema;
  };
};

export * from './lib/docs';
export function userHasRole(userRole: ROLE, requiredRole: ROLE) {
  if (requiredRole == userRole) return true;

  if (userRole == ROLE.ADMIN) {
    return true;
  }
  if (requiredRole == ROLE.VIEWER && userRole == ROLE.ANALYST) {
    return true;
  }
  return false;
}

export const ROLE_CONFIG = {
  [ROLE.ADMIN]: {
    name: 'Admin',
    role: ROLE.ADMIN,
    description: 'Full access to all features'
  },
  [ROLE.ANALYST]: {
    name: 'Analyst',
    role: ROLE.ANALYST,
    description: 'Access to all features'
  },
  [ROLE.VIEWER]: {
    name: 'Viewer',
    role: ROLE.VIEWER,
    description: 'Limited access to features'
  }
};

export function oxfordComma(items?: string[]) {
  if (items == null || items.length === 0) {
    return '';
  }
  items = [...new Set(items)];
  if (items.length == 1) {
    return items[0];
  }
  if (items.length == 2) {
    return `${items[0]} and ${items[1]}`;
  }
  const last = items.pop();
  return `${items.join(', ')}, and ${last}`;
}

export function forEveryJSONProperty(
  obj: Record<string, any>,
  callback: (key: string, data: any) => any
) {
  const newObj = Object.assign({}, obj);
  for (const [key, value] of Object.entries(newObj)) {
    if (Array.isArray(value)) {
      for (const item of value) {
        newObj[key] = forEveryJSONProperty(item, callback);
      }
    } else if (typeof value === 'object' && value != null) {
      newObj[key] = forEveryJSONProperty(value, callback);
    } else {
      newObj[key] = callback(key, value);
    }
  }
  return newObj;
}

export function slugify(str: string) {
  return str.toLowerCase().replace(/[^a-z0-9]/g, '-');
}

export function enumify(str: string) {
  return slugify(str).toUpperCase().replace(/-/g, '_');
}

/**
 * Splits an array into batches of specified size
 * @param array - The array to split into batches
 * @param batchSize - The size of each batch
 * @returns Array of batches
 */
export function batchArray<T>(array: T[], batchSize: number): T[][] {
  const batches: T[][] = [];
  for (let i = 0; i < array.length; i += batchSize) {
    batches.push(array.slice(i, i + batchSize));
  }
  return batches;
}
