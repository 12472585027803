import {
  type Nullable,
  Verdict,
  getVerdictConfigByVerdict
} from './shared.types';

export function getCategoryTitle(category: Category) {
  const categoryConfig = getCategory(category);
  return categoryConfig.name;
}

export function getCategoryTitleWithVerdict(
  category: Category,
  verdict: Verdict
) {
  const categoryConfig = getCategory(category);
  const verdictTitle = getVerdictConfigByVerdict(verdict).display;
  return `${verdictTitle} ${categoryConfig.name}`;
}

export enum Category {
  OTHER__DIAGNOSTIC = 'OTHER__DIAGNOSTIC',
  OTHER__INFORMATIONAL_EVENT = 'OTHER__INFORMATIONAL_EVENT',
  OTHER__WARNING = 'OTHER__WARNING',
  OTHER__UNKNOWN = 'OTHER__UNKNOWN',
  OTHER__DECEPTION_TRIGGER = 'OTHER__DECEPTION_TRIGGER',

  CLOUD__INVOCATION = 'CLOUD__INVOCATION',
  CLOUD__DISCOVERY = 'CLOUD__DISCOVERY',
  CLOUD__DATA_TRANSFER = 'CLOUD__DATA_TRANSFER',
  CLOUD__PERSISTENCE = 'CLOUD__PERSISTENCE',

  ENDPOINT__DISCOVERY = 'ENDPOINT__DISCOVERY',
  ENDPOINT__EXECUTION = 'ENDPOINT__EXECUTION',
  ENDPOINT__LIVE_OFF_THE_LAND = 'ENDPOINT__LIVE_OFF_THE_LAND',
  ENDPOINT__NUISANCE = 'ENDPOINT__NUISANCE',
  ENDPOINT__MALWARE_DISCOVERY = 'ENDPOINT__MALWARE_DISCOVERY',
  ENDPOINT__MALWARE_EXECUTION = 'ENDPOINT__MALWARE_EXECUTION',
  ENDPOINT__LATE_STAGE = 'ENDPOINT__LATE_STAGE',
  ENDPOINT__PERSISTENCE = 'ENDPOINT__PERSISTENCE',
  ENDPOINT__REMOTE_MANAGEMENT = 'ENDPOINT__REMOTE_MANAGEMENT',

  IDENTITY__LOGIN = 'IDENTITY__LOGIN',
  IDENTITY__DISCOVERY = 'IDENTITY__DISCOVERY',
  IDENTITY__BRUTE_FORCE = 'IDENTITY__BRUTE_FORCE',
  IDENTITY__PUBLIC_CREDENTIAL_EXPOSURE = 'IDENTITY__PUBLIC_CREDENTIAL_EXPOSURE',
  IDENTITY__PRIVATE_CREDENTIAL_EXPOSURE = 'IDENTITY__PRIVATE_CREDENTIAL_EXPOSURE',
  IDENTITY__PERSISTENCE = 'IDENTITY__PERSISTENCE',

  NETWORK__INBOUND_CONNECTION = 'NETWORK__INBOUND_CONNECTION',
  NETWORK__OUTBOUND_CONNECTION = 'NETWORK__OUTBOUND_CONNECTION',
  NETWORK__THREAT_INDICATOR = 'NETWORK__THREAT_INDICATOR',
  NETWORK__PHISHING = 'NETWORK__PHISHING',
  NETWORK__NOISY = 'NETWORK__NOISY',

  EMAIL__PHISHING = 'EMAIL__PHISHING',
  EMAIL__PHISHING_REPORTED = 'EMAIL__PHISHING_REPORTED',
  EMAIL__MALWARE = 'EMAIL__MALWARE',
  EMAIL__MALICIOUS_LINK = 'EMAIL__MALICIOUS_LINK',
  EMAIL__GRAYMAIL = 'EMAIL__GRAYMAIL',
  EMAIL__SPAM = 'EMAIL__SPAM',

  DATA__DATA_TRANSFER = 'DATA__DATA_TRANSFER',
  DATA__DATA_SHARE = 'DATA__DATA_SHARE',

  POSTURE__POSTURE = 'POSTURE__POSTURE'
}

export enum CategoryClass {
  ENDPOINT = 'ENDPOINT',
  IDENTITY = 'IDENTITY',
  CLOUD = 'CLOUD',
  EMAIL = 'EMAIL',
  NETWORK = 'NETWORK',
  DATA = 'DATA',
  POSTURE = 'POSTURE',
  OTHER = 'OTHER'
}

export interface ICategoryClassConfig {
  name: string;
  icon: string;
  description: string;
}

export const CategoryClassConfig: Record<CategoryClass, ICategoryClassConfig> =
  {
    [CategoryClass.ENDPOINT]: {
      name: 'Endpoint',
      icon: 'laptop',
      description: 'Detections discovered on endpoints in your organization'
    },
    [CategoryClass.IDENTITY]: {
      name: 'Identity',
      icon: 'shield-user',
      description: 'Detections discovered on identities in your organization'
    },
    [CategoryClass.CLOUD]: {
      name: 'Cloud',
      icon: 'cloud',
      description:
        'Cloud interactions and anomalous activity on your cloud services'
    },
    [CategoryClass.EMAIL]: {
      name: 'Email',
      icon: 'mail',
      description:
        'Detections discovered on email services in your organization'
    },
    [CategoryClass.NETWORK]: {
      name: 'Network',
      icon: 'network',
      description: 'Network interactions and anomalous activity on your network'
    },
    [CategoryClass.DATA]: {
      name: 'Data',
      icon: 'database',
      description: 'Anomalous interactions with data in your organization'
    },
    [CategoryClass.POSTURE]: {
      name: 'Posture',
      icon: 'shield',
      description:
        'Insecure configurations on applications and devices in your organization'
    },
    [CategoryClass.OTHER]: {
      name: 'Other',
      icon: 'question',
      description: 'General and unmapped detections'
    }
  };

/**
 * Rules for each category that change the way verdicts are handled
 * Each category should have a default rule that describes the default behavior of the category
 *
 * Make sure to update the category config when adding new rules
 */
export enum CategoryRule {
  CLOUD__INVOCATION = 'CLOUD__INVOCATION',
  CLOUD__DISCOVERY = 'CLOUD__DISCOVERY',
  CLOUD__DATA_TRANSFER = 'CLOUD__DATA_TRANSFER',
  CLOUD__PERSISTENCE = 'CLOUD__PERSISTENCE',

  ENDPOINT__DISCOVERY = 'ENDPOINT__DISCOVERY',
  ENDPOINT__LIVE_OFF_THE_LAND__BLOCKED_HVA = 'ENDPOINT__LIVE_OFF_THE_LAND__BLOCKED_HVA',
  ENDPOINT__LIVE_OFF_THE_LAND__BLOCKED = 'ENDPOINT__LIVE_OFF_THE_LAND__BLOCKED',
  ENDPOINT__LIVE_OFF_THE_LAND = 'ENDPOINT__LIVE_OFF_THE_LAND',
  ENDPOINT__LIVE_OFF_THE_LAND__USER_TECHNICAL = 'ENDPOINT__LIVE_OFF_THE_LAND__USER_TECHNICAL',
  ENDPOINT__NUISANCE = 'ENDPOINT__NUISANCE',
  ENDPOINT__EXECUTION = 'ENDPOINT__EXECUTION',
  ENDPOINT__EXECUTION__TECHNICAL_USER = 'ENDPOINT__EXECUTION__TECHNICAL_USER',
  ENDPOINT__EXECUTION__BENIGN = 'ENDPOINT__EXECUTION__BENIGN',
  ENDPOINT__EXECUTION__BLOCKED = 'ENDPOINT__EXECUTION__BLOCKED',
  ENDPOINT__EXECUTION__BLOCKED_HVA = 'ENDPOINT__EXECUTION__BLOCKED_HVA',
  ENDPOINT__MALWARE_DISCOVERY = 'ENDPOINT__MALWARE_DISCOVERY',
  ENDPOINT__MALWARE_DISCOVERY__BLOCKED = 'ENDPOINT__MALWARE_DISCOVERY__BLOCKED',
  ENDPOINT__MALWARE_DISCOVERY__BLOCKED_HVA = 'ENDPOINT__MALWARE_DISCOVERY__BLOCKED_HVA',
  ENDPOINT__MALWARE_EXECUTION = 'ENDPOINT__MALWARE_EXECUTION',
  ENDPOINT__MALWARE_EXECUTION__BLOCKED = 'ENDPOINT__MALWARE_EXECUTION__BLOCKED',
  ENDPOINT__MALWARE_EXECUTION__BLOCKED_HVA = 'ENDPOINT__MALWARE_EXECUTION__BLOCKED_HVA',
  ENDPOINT__LATE_STAGE = 'ENDPOINT__LATE_STAGE',
  ENDPOINT__LATE_STAGE__BLOCKED = 'ENDPOINT__LATE_STAGE__BLOCKED',
  ENDPOINT__LATE_STAGE__BLOCKED_HVA = 'ENDPOINT__LATE_STAGE__BLOCKED_HVA',
  ENDPOINT__REMOTE_MANAGEMENT = 'ENDPOINT__REMOTE_MANAGEMENT',

  IDENTITY__DISCOVERY = 'IDENTITY__DISCOVERY',

  IDENTITY__LOGIN = 'IDENTITY__LOGIN',
  IDENTITY__LOGIN__BLOCKED = 'IDENTITY__LOGIN__BLOCKED',
  IDENTITY__LOGIN__BLOCKED_VIP = 'IDENTITY__LOGIN__BLOCKED_VIP',
  IDENTITY__LOGIN__BLOCKED_PRIVACY_POLICY = 'IDENTITY__LOGIN__BLOCKED_PRIVACY_POLICY',
  IDENTITY__LOGIN__KNOWN_SAFE_LOCATION = 'IDENTITY__LOGIN__KNOWN_SAFE_LOCATION',
  IDENTITY__LOGIN__MANAGED_DEVICE = 'IDENTITY__LOGIN__MANAGED_DEVICE',
  IDENTITY__LOGIN__ADVERSARIAL_COUNTRY = 'IDENTITY__LOGIN__ADVERSARIAL_COUNTRY',
  IDENTITY__LOGIN__KNOWN_UNSAFE_LOCATION = 'IDENTITY__LOGIN__KNOWN_UNSAFE_LOCATION',
  IDENTITY__PERSISTENCE = 'IDENTITY__PERSISTENCE',
  IDENTITY__PERSISTENCE__TECHNICAL_USER = 'IDENTITY__PERSISTENCE__TECHNICAL_USER',
  IDENTITY__BRUTE_FORCE = 'IDENTITY__BRUTE_FORCE',
  IDENTITY__PUBLIC_CREDENTIAL_EXPOSURE = 'IDENTITY__PUBLIC_CREDENTIAL_EXPOSURE',
  IDENTITY__PRIVATE_CREDENTIAL_EXPOSURE = 'IDENTITY__PRIVATE_CREDENTIAL_EXPOSURE',

  ENDPOINT__PERSISTENCE__BLOCKED = 'ENDPOINT__PERSISTENCE__BLOCKED',
  ENDPOINT__PERSISTENCE__BLOCKED_HVA = 'ENDPOINT__PERSISTENCE__BLOCKED_HVA',
  ENDPOINT__PERSISTENCE__USER_TECHNICAL = 'ENDPOINT__PERSISTENCE__USER_TECHNICAL',
  ENDPOINT__PERSISTENCE = 'ENDPOINT__PERSISTENCE',

  NETWORK__INBOUND_CONNECTION = 'NETWORK__INBOUND_CONNECTION',
  NETWORK__INBOUND_CONNECTION__BLOCKED = 'NETWORK__INBOUND_CONNECTION__BLOCKED',
  NETWORK__OUTBOUND_CONNECTION = 'NETWORK__OUTBOUND_CONNECTION',
  NETWORK__OUTBOUND_CONNECTION__BLOCKED = 'NETWORK__OUTBOUND_CONNECTION__BLOCKED',
  NETWORK__OUTBOUND_CONNECTION__BLOCKED_HVA = 'NETWORK__OUTBOUND_CONNECTION__BLOCKED_HVA',
  NETWORK__THREAT_INDICATOR = 'NETWORK__THREAT_INDICATOR',
  NETWORK__PHISHING = 'NETWORK__PHISHING',
  NETWORK__NOISY = 'NETWORK__NOISY',

  EMAIL__PHISHING = 'EMAIL__PHISHING',
  EMAIL__PHISHING__BLOCKED = 'EMAIL__PHISHING__BLOCKED',
  EMAIL__PHISHING_REPORTED = 'EMAIL__PHISHING_REPORTED',
  EMAIL__MALWARE = 'EMAIL__MALWARE',
  EMAIL__MALWARE__BLOCKED = 'EMAIL__MALWARE__BLOCKED',
  EMAIL__MALICIOUS_LINK = 'EMAIL__MALICIOUS_LINK',
  EMAIL__MALICIOUS_LINK__BLOCKED = 'EMAIL__MALICIOUS_LINK__BLOCKED',
  EMAIL__GRAYMAIL = 'EMAIL__GRAYMAIL',
  EMAIL__SPAM = 'EMAIL__SPAM',

  DATA__DATA_TRANSFER = 'DATA__DATA_TRANSFER',
  DATA__DATA_SHARE = 'DATA__DATA_SHARE',

  POSTURE__POSTURE = 'POSTURE__POSTURE',

  OTHER__DECEPTION_TRIGGER = 'OTHER__DECEPTION_TRIGGER',
  OTHER__UNKNOWN = 'OTHER__UNKNOWN',
  OTHER__DIAGNOSTIC = 'OTHER__DIAGNOSTIC',
  OTHER__INFORMATIONAL_EVENT = 'OTHER__INFORMATIONAL_EVENT',
  OTHER__WARNING = 'OTHER__WARNING'
}

export type TCategoryRule = keyof typeof CategoryRule;

export interface ICategoryRule {
  category: Category;
  /**
   * Should the detection be escalated
   */
  escalate?: boolean;
  /**
   * Default verdict for this category
   */
  verdict?: Verdict;
  /**
   * Should chat ops be performed
   */
  chatOps?: boolean;
  /**
   * Do we need to verify the identity of the user when doing chat ops
   */
  chatOpsMFA?: boolean;
  /**
   * Should we escalated to the manager when chat ops times out or the user cannot MFA
   */
  managerChatOps?: boolean;
  /**
   * Should we perform chat ops for VIP users
   */
  vipChatOps?: boolean;
  /**
   * Should we close the detection
   */
  close?: boolean;
  /**
   * Should we contain the users associated with the detection
   */
  containUser?: boolean;
  /**
   * Should we skip this rule and let the others match
   */
  disabled?: boolean;
  /**
   * Should we contain the endpoints associated with the detection
   */
  containEndpoint?: boolean;

  /**
   * What verdict should we use if the chat ops timeout
   */
  chatOpsTimeoutVerdict?: Verdict;

  /**
   * Should this be the default rule for the category
   */
  default?: boolean;

  /**
   * The query to use to determine if this rule should be used
   */
  query?: Nullable<string>;

  /**
   * The order of the rule, lower goes first
   * -1 means last
   */
  order?: number;

  name: string;
  description: string;
}

export interface ICategoryRuleWithMetadata extends ICategoryRule {
  class: CategoryClass;
  category: Category;
}

export interface ICategoryConfig {
  class: CategoryClass;
  category: Category;
  name: string;
  description: string;
}

export function getCategory(category: Category) {
  return CategoryConfig[category];
}

export function getRule(rule: TCategoryRule): ICategoryRuleWithMetadata {
  const ruleConfig = RuleConfig[rule];
  return {
    ...ruleConfig,
    class: CategoryConfig[ruleConfig.category].class,
    category: ruleConfig.category
  };
}

export const RuleConfig: Record<CategoryRule, ICategoryRule> = {
  [CategoryRule.OTHER__UNKNOWN]: {
    category: Category.OTHER__UNKNOWN,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected',
    escalate: true
  },
  [CategoryRule.OTHER__DIAGNOSTIC]: {
    category: Category.OTHER__DIAGNOSTIC,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected',
    close: true,
    verdict: Verdict.BENIGN
  },
  [CategoryRule.OTHER__INFORMATIONAL_EVENT]: {
    category: Category.OTHER__INFORMATIONAL_EVENT,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected',
    close: true,
    verdict: Verdict.BENIGN
  },
  [CategoryRule.OTHER__WARNING]: {
    category: Category.OTHER__WARNING,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected',
    escalate: true,
    verdict: Verdict.SUSPICIOUS
  },
  [CategoryRule.OTHER__DECEPTION_TRIGGER]: {
    category: Category.OTHER__DECEPTION_TRIGGER,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected',
    escalate: true,
    verdict: Verdict.MALICIOUS
  },
  [CategoryRule.CLOUD__PERSISTENCE]: {
    category: Category.CLOUD__PERSISTENCE,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected',
    chatOps: true,
    managerChatOps: true,
    chatOpsMFA: true
  },
  [CategoryRule.CLOUD__INVOCATION]: {
    category: Category.CLOUD__INVOCATION,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected',
    chatOps: true,
    chatOpsMFA: true
  },
  [CategoryRule.CLOUD__DISCOVERY]: {
    category: Category.CLOUD__DISCOVERY,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected',
    close: true
  },
  [CategoryRule.CLOUD__DATA_TRANSFER]: {
    category: Category.CLOUD__DATA_TRANSFER,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected',
    escalate: true
  },
  [CategoryRule.ENDPOINT__DISCOVERY]: {
    category: Category.ENDPOINT__DISCOVERY,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected',
    close: true,
    verdict: Verdict.BENIGN
  },
  [CategoryRule.ENDPOINT__NUISANCE]: {
    category: Category.ENDPOINT__NUISANCE,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected',
    close: true,
    verdict: Verdict.BENIGN
  },
  [CategoryRule.ENDPOINT__EXECUTION__BENIGN]: {
    category: Category.ENDPOINT__EXECUTION,
    close: true,
    name: 'Execution benign',
    verdict: Verdict.BENIGN,
    description: 'The process execution has been identified as benign'
  },
  [CategoryRule.ENDPOINT__EXECUTION__BLOCKED_HVA]: {
    category: Category.ENDPOINT__EXECUTION,
    escalate: true,
    name: 'Execution blocked on high value asset',
    description: 'The process execution has been blocked on a high value asset'
  },
  [CategoryRule.ENDPOINT__EXECUTION__BLOCKED]: {
    category: Category.ENDPOINT__EXECUTION,
    close: true,
    name: 'Execution blocked',
    description: 'The process execution has been blocked'
  },
  [CategoryRule.ENDPOINT__EXECUTION__TECHNICAL_USER]: {
    category: Category.ENDPOINT__EXECUTION,
    chatOps: true,
    chatOpsMFA: true,
    managerChatOps: true,
    vipChatOps: true,
    name: 'Execution performed by technical user',
    description:
      'The user executing the process has been identified as technical'
  },
  [CategoryRule.ENDPOINT__EXECUTION]: {
    category: Category.ENDPOINT__EXECUTION,
    escalate: true,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected'
  },
  [CategoryRule.ENDPOINT__REMOTE_MANAGEMENT]: {
    category: Category.ENDPOINT__REMOTE_MANAGEMENT,
    escalate: true,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected'
  },
  [CategoryRule.ENDPOINT__PERSISTENCE__BLOCKED_HVA]: {
    category: Category.ENDPOINT__PERSISTENCE,
    escalate: true,
    name: 'Persistence blocked on high value asset',
    description: 'The persistence has been blocked on a high value asset'
  },
  [CategoryRule.ENDPOINT__PERSISTENCE__BLOCKED]: {
    category: Category.ENDPOINT__PERSISTENCE,
    close: true,
    name: 'Persistence blocked',
    description: 'The persistence has been blocked on an endpoint'
  },
  [CategoryRule.ENDPOINT__PERSISTENCE__USER_TECHNICAL]: {
    category: Category.ENDPOINT__PERSISTENCE,
    chatOps: true,
    chatOpsMFA: true,
    managerChatOps: true,
    vipChatOps: true,
    name: 'Persistence performed by technical user',
    description:
      'The user executing this process has been identified as technical'
  },
  [CategoryRule.ENDPOINT__PERSISTENCE]: {
    category: Category.ENDPOINT__PERSISTENCE,
    escalate: true,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected'
  },
  [CategoryRule.ENDPOINT__LIVE_OFF_THE_LAND__BLOCKED_HVA]: {
    category: Category.ENDPOINT__LIVE_OFF_THE_LAND,
    escalate: true,
    name: 'Live off the land tool blocked on high value asset',
    description:
      'The live off the land tool has been blocked on a high value asset'
  },
  [CategoryRule.ENDPOINT__LIVE_OFF_THE_LAND__BLOCKED]: {
    category: Category.ENDPOINT__LIVE_OFF_THE_LAND,
    close: true,
    name: 'Live off the land tool blocked',
    description: 'The live off the land tool has been blocked on an endpoint'
  },
  [CategoryRule.ENDPOINT__LIVE_OFF_THE_LAND__USER_TECHNICAL]: {
    category: Category.ENDPOINT__LIVE_OFF_THE_LAND,
    chatOps: true,
    chatOpsMFA: true,
    name: 'Live off the land tool used by technical user',
    description:
      'The user executing this process has been identified as technical'
  },
  [CategoryRule.ENDPOINT__LIVE_OFF_THE_LAND]: {
    category: Category.ENDPOINT__LIVE_OFF_THE_LAND,
    escalate: true,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected'
  },
  [CategoryRule.ENDPOINT__MALWARE_DISCOVERY__BLOCKED_HVA]: {
    category: Category.ENDPOINT__MALWARE_DISCOVERY,
    escalate: true,
    name: 'Malware blocked on high value asset',
    description:
      'A dormant malware file has been discovered and quarantined on a high value asset'
  },
  [CategoryRule.ENDPOINT__MALWARE_DISCOVERY__BLOCKED]: {
    category: Category.ENDPOINT__MALWARE_DISCOVERY,
    close: true,
    name: 'Malware blocked',
    description:
      'A dormant malware file has been discovered and quarantined on an endpoint'
  },
  [CategoryRule.ENDPOINT__MALWARE_DISCOVERY]: {
    category: Category.ENDPOINT__MALWARE_DISCOVERY,
    escalate: true,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected'
  },
  [CategoryRule.ENDPOINT__MALWARE_EXECUTION__BLOCKED_HVA]: {
    category: Category.ENDPOINT__MALWARE_EXECUTION,
    escalate: true,
    name: 'Malware blocked on high value asset',
    description:
      'An active malware process has been blocked on a high value asset'
  },
  [CategoryRule.ENDPOINT__MALWARE_EXECUTION__BLOCKED]: {
    category: Category.ENDPOINT__MALWARE_EXECUTION,
    close: true,
    name: 'Malware blocked',
    description: 'An active malware process has been blocked on an endpoint'
  },
  [CategoryRule.ENDPOINT__MALWARE_EXECUTION]: {
    category: Category.ENDPOINT__MALWARE_EXECUTION,
    escalate: true,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected'
  },
  [CategoryRule.ENDPOINT__LATE_STAGE__BLOCKED_HVA]: {
    category: Category.ENDPOINT__LATE_STAGE,
    escalate: true,
    name: 'Late stage tool blocked on high value asset',
    verdict: Verdict.MALICIOUS,
    description:
      'The process was killed or blocked but was on a high value asset'
  },
  [CategoryRule.ENDPOINT__LATE_STAGE__BLOCKED]: {
    category: Category.ENDPOINT__LATE_STAGE,
    escalate: true,
    name: 'Late stage tool blocked',
    verdict: Verdict.MALICIOUS,
    description: 'The process was killed or blocked'
  },
  [CategoryRule.ENDPOINT__LATE_STAGE]: {
    category: Category.ENDPOINT__LATE_STAGE,
    containEndpoint: true,
    escalate: true,
    name: 'Default rule',
    default: true,
    verdict: Verdict.MALICIOUS,
    description: 'Default rule for when this category is detected'
  },
  [CategoryRule.EMAIL__PHISHING_REPORTED]: {
    category: Category.EMAIL__PHISHING_REPORTED,
    close: true,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected'
  },
  [CategoryRule.EMAIL__SPAM]: {
    category: Category.EMAIL__SPAM,
    close: true,
    name: 'Default rule',
    default: true,
    verdict: Verdict.BENIGN,
    description: 'Default rule for when this category is detected'
  },
  [CategoryRule.EMAIL__PHISHING__BLOCKED]: {
    category: Category.EMAIL__PHISHING,
    close: true,
    name: 'Phishing blocked',
    description: 'A phishing email has been blocked'
  },
  [CategoryRule.EMAIL__PHISHING]: {
    category: Category.EMAIL__PHISHING,
    chatOps: true,
    chatOpsMFA: true,
    managerChatOps: false,
    name: 'Default rule',
    default: true,
    chatOpsTimeoutVerdict: Verdict.BENIGN,
    description: 'Default rule for when this category is detected'
  },
  [CategoryRule.EMAIL__MALWARE__BLOCKED]: {
    category: Category.EMAIL__MALWARE,
    close: true,
    name: 'Malware blocked',
    description: 'A malware email has been blocked'
  },
  [CategoryRule.EMAIL__MALWARE]: {
    category: Category.EMAIL__MALWARE,
    chatOps: true,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected'
  },
  [CategoryRule.EMAIL__MALICIOUS_LINK__BLOCKED]: {
    category: Category.EMAIL__MALICIOUS_LINK,
    close: true,
    name: 'Malicious link prevented',
    description:
      "A malicious link has been blocked or removed from the user's inbox"
  },
  [CategoryRule.EMAIL__MALICIOUS_LINK]: {
    category: Category.EMAIL__MALICIOUS_LINK,
    close: true,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected'
  },
  [CategoryRule.EMAIL__GRAYMAIL]: {
    category: Category.EMAIL__GRAYMAIL,
    close: true,
    name: 'Default rule',
    default: true,
    verdict: Verdict.BENIGN,
    description: 'Default rule for when this category is detected'
  },
  [CategoryRule.IDENTITY__LOGIN__BLOCKED_VIP]: {
    category: Category.IDENTITY__LOGIN,
    escalate: true,
    name: 'Login mitigated for VIP',
    description: 'A login has been blocked or mitigated for a VIP user'
  },
  [CategoryRule.IDENTITY__LOGIN__BLOCKED_PRIVACY_POLICY]: {
    category: Category.IDENTITY__LOGIN,
    close: true,
    name: 'Login mitigated from privacy IP',
    description:
      'A login has been blocked or mitigated for a user coming from an IP address with privacy features'
  },
  [CategoryRule.IDENTITY__LOGIN__BLOCKED]: {
    category: Category.IDENTITY__LOGIN,
    close: true,
    name: 'Login blocked',
    description: 'A login has been blocked'
  },
  [CategoryRule.IDENTITY__LOGIN__MANAGED_DEVICE]: {
    category: Category.IDENTITY__LOGIN,
    disabled: true,
    name: 'Login from managed device',
    verdict: Verdict.BENIGN,
    description: 'A login has been performed from a managed device'
  },
  [CategoryRule.IDENTITY__LOGIN__KNOWN_UNSAFE_LOCATION]: {
    category: Category.IDENTITY__LOGIN,
    escalate: true,
    containUser: true,
    name: 'Login from known unsafe location',
    query:
      '(locations.known@=true AND locations.safe@=false) OR (ips.known@=true AND ips.safe@=false)',
    description: 'A login has been performed from a known unsafe location'
  },
  [CategoryRule.IDENTITY__LOGIN__KNOWN_SAFE_LOCATION]: {
    category: Category.IDENTITY__LOGIN,
    close: true,
    name: 'Login from known safe location',
    verdict: Verdict.BENIGN,
    description: 'A login has been performed from a known safe location'
  },
  [CategoryRule.IDENTITY__LOGIN__ADVERSARIAL_COUNTRY]: {
    category: Category.IDENTITY__LOGIN,
    escalate: true,
    containUser: true,
    name: 'Login from adversarial country',
    description: 'A login has been performed from an adversarial country'
  },
  [CategoryRule.IDENTITY__LOGIN]: {
    category: Category.IDENTITY__LOGIN,
    chatOps: true,
    chatOpsMFA: true,
    managerChatOps: true,
    vipChatOps: true,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected'
  },
  [CategoryRule.IDENTITY__DISCOVERY]: {
    category: Category.IDENTITY__DISCOVERY,
    chatOps: true,
    chatOpsMFA: true,
    managerChatOps: true,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected'
  },
  [CategoryRule.IDENTITY__BRUTE_FORCE]: {
    category: Category.IDENTITY__BRUTE_FORCE,
    close: true,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected'
  },
  [CategoryRule.IDENTITY__PERSISTENCE__TECHNICAL_USER]: {
    category: Category.IDENTITY__PERSISTENCE,
    chatOps: true,
    chatOpsMFA: true,
    managerChatOps: true,
    vipChatOps: true,
    name: 'Persistence performed by technical user',
    description:
      'The user executing the process has been identified as technical'
  },
  [CategoryRule.IDENTITY__PERSISTENCE]: {
    category: Category.IDENTITY__PERSISTENCE,
    escalate: true,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected'
  },
  [CategoryRule.IDENTITY__PUBLIC_CREDENTIAL_EXPOSURE]: {
    category: Category.IDENTITY__PUBLIC_CREDENTIAL_EXPOSURE,
    chatOps: true,
    vipChatOps: true,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected'
  },
  [CategoryRule.IDENTITY__PRIVATE_CREDENTIAL_EXPOSURE]: {
    category: Category.IDENTITY__PRIVATE_CREDENTIAL_EXPOSURE,
    chatOps: true,
    chatOpsMFA: true,
    vipChatOps: true,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected'
  },
  [CategoryRule.NETWORK__NOISY]: {
    category: Category.NETWORK__NOISY,
    close: true,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected'
  },
  [CategoryRule.NETWORK__INBOUND_CONNECTION__BLOCKED]: {
    category: Category.NETWORK__INBOUND_CONNECTION,
    close: true,
    name: 'Inbound connection blocked',
    description: 'The connection was blocked'
  },
  [CategoryRule.NETWORK__INBOUND_CONNECTION]: {
    category: Category.NETWORK__INBOUND_CONNECTION,
    escalate: true,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected'
  },
  [CategoryRule.NETWORK__PHISHING]: {
    category: Category.NETWORK__PHISHING,
    chatOps: true,
    chatOpsMFA: true,
    vipChatOps: true,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected'
  },
  [CategoryRule.NETWORK__OUTBOUND_CONNECTION__BLOCKED_HVA]: {
    category: Category.NETWORK__OUTBOUND_CONNECTION,
    escalate: true,
    name: 'Outbound connection blocked on high value asset',
    description: 'The connection was blocked on a high value asset'
  },
  [CategoryRule.NETWORK__OUTBOUND_CONNECTION__BLOCKED]: {
    category: Category.NETWORK__OUTBOUND_CONNECTION,
    close: true,
    name: 'Outbound connection blocked',
    description: 'The connection was blocked'
  },
  [CategoryRule.NETWORK__OUTBOUND_CONNECTION]: {
    category: Category.NETWORK__OUTBOUND_CONNECTION,
    escalate: true,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected'
  },
  [CategoryRule.NETWORK__THREAT_INDICATOR]: {
    category: Category.NETWORK__THREAT_INDICATOR,
    close: true,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected'
  },
  [CategoryRule.DATA__DATA_TRANSFER]: {
    category: Category.DATA__DATA_TRANSFER,
    chatOps: true,
    chatOpsMFA: true,
    managerChatOps: true,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected'
  },
  [CategoryRule.DATA__DATA_SHARE]: {
    category: Category.DATA__DATA_SHARE,
    chatOps: true,
    chatOpsMFA: true,
    managerChatOps: true,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected'
  },
  [CategoryRule.POSTURE__POSTURE]: {
    category: Category.POSTURE__POSTURE,
    close: true,
    name: 'Default rule',
    default: true,
    description: 'Default rule for when this category is detected'
  }
};

export const CategoryConfig: Record<Category, ICategoryConfig> = {
  [Category.OTHER__UNKNOWN]: {
    class: CategoryClass.OTHER,
    category: Category.OTHER__UNKNOWN,
    name: 'Unknown event',
    description: 'An event that Wirespeed was unable to determine the type of'
  },
  [Category.OTHER__DIAGNOSTIC]: {
    class: CategoryClass.OTHER,
    category: Category.OTHER__DIAGNOSTIC,
    name: 'Diagnostic event',
    description: 'A testing or diagnostic event'
  },
  [Category.OTHER__INFORMATIONAL_EVENT]: {
    class: CategoryClass.OTHER,
    category: Category.OTHER__INFORMATIONAL_EVENT,
    name: 'Informational event',
    description:
      'An event that by itself does not indicate malicious activity, but may include useful information'
  },
  [Category.OTHER__WARNING]: {
    class: CategoryClass.OTHER,
    category: Category.OTHER__WARNING,
    name: 'Warning',
    description:
      'An event that Wirespeed has not created automated response for, but requires attention'
  },
  [Category.OTHER__DECEPTION_TRIGGER]: {
    class: CategoryClass.OTHER,
    category: Category.OTHER__DECEPTION_TRIGGER,
    name: 'Deception Trigger',
    description: 'A deception (canary) has been triggered in your environment'
  },
  [Category.CLOUD__PERSISTENCE]: {
    class: CategoryClass.CLOUD,
    category: Category.CLOUD__PERSISTENCE,
    name: 'Persistence',
    description: 'A process or user is attempting to persist on a cloud service'
  },
  [Category.CLOUD__INVOCATION]: {
    class: CategoryClass.CLOUD,
    category: Category.CLOUD__INVOCATION,
    name: 'Invocation',
    description: 'A cloud service API request has been identified as suspicious'
  },
  [Category.CLOUD__DISCOVERY]: {
    class: CategoryClass.CLOUD,
    category: Category.CLOUD__DISCOVERY,
    name: 'Discovery',
    description:
      'A process or user is attempting to learn information about your cloud environment in an unusual manner'
  },
  [Category.CLOUD__DATA_TRANSFER]: {
    class: CategoryClass.CLOUD,
    category: Category.CLOUD__DATA_TRANSFER,
    name: 'Data Transfer',
    description:
      'A process or user is attempting to transfer data in an unusual manner'
  },
  [Category.ENDPOINT__DISCOVERY]: {
    class: CategoryClass.ENDPOINT,
    category: Category.ENDPOINT__DISCOVERY,
    name: 'Discovery',
    description:
      'A process or user is attempting to discover additional information about its environment'
  },
  [Category.ENDPOINT__EXECUTION]: {
    class: CategoryClass.ENDPOINT,
    category: Category.ENDPOINT__EXECUTION,
    name: 'Execution',
    description:
      'A running process has been determined to be operating in an anomalous manner'
  },
  [Category.ENDPOINT__NUISANCE]: {
    class: CategoryClass.ENDPOINT,
    category: Category.ENDPOINT__NUISANCE,
    name: 'Nuisance',
    description: 'A process has been determined to be a nuisance'
  },
  [Category.ENDPOINT__REMOTE_MANAGEMENT]: {
    class: CategoryClass.ENDPOINT,
    category: Category.ENDPOINT__REMOTE_MANAGEMENT,
    name: 'Remote Management',
    description: 'A remote management tool is being used on an endpoint'
  },
  [Category.ENDPOINT__PERSISTENCE]: {
    class: CategoryClass.ENDPOINT,
    category: Category.ENDPOINT__PERSISTENCE,
    name: 'Persistence',
    description: 'A process or user is attempting to persist on an endpoint'
  },
  [Category.ENDPOINT__LIVE_OFF_THE_LAND]: {
    class: CategoryClass.ENDPOINT,
    category: Category.ENDPOINT__LIVE_OFF_THE_LAND,
    name: 'Live Off The Land Execution',
    description:
      'A native tool on the endpoint is being used in an anomalous manner'
  },
  [Category.ENDPOINT__MALWARE_DISCOVERY]: {
    class: CategoryClass.ENDPOINT,
    category: Category.ENDPOINT__MALWARE_DISCOVERY,
    name: 'Malware Discovery',
    description: 'A dormant malware file has been discovered on an endpoint'
  },
  [Category.ENDPOINT__MALWARE_EXECUTION]: {
    class: CategoryClass.ENDPOINT,
    category: Category.ENDPOINT__MALWARE_EXECUTION,
    name: 'Malware Execution',
    description: 'An active malware process has been detected on an endpoint'
  },
  [Category.ENDPOINT__LATE_STAGE]: {
    class: CategoryClass.ENDPOINT,
    category: Category.ENDPOINT__LATE_STAGE,
    name: 'Late Stage Execution',
    description:
      'A process running a late stage tool has been detected on an endpoint'
  },

  [Category.EMAIL__PHISHING_REPORTED]: {
    class: CategoryClass.EMAIL,
    category: Category.EMAIL__PHISHING_REPORTED,
    name: 'Reported Phishing',
    description: 'A phishing email has been reported by a user'
  },
  [Category.EMAIL__SPAM]: {
    class: CategoryClass.EMAIL,
    category: Category.EMAIL__SPAM,
    name: 'Spam',
    description: 'An email has been identified as spam'
  },
  [Category.EMAIL__PHISHING]: {
    class: CategoryClass.EMAIL,
    category: Category.EMAIL__PHISHING,
    name: 'Phishing',
    description: 'An email has been identified as phishing'
  },
  [Category.EMAIL__MALWARE]: {
    class: CategoryClass.EMAIL,
    category: Category.EMAIL__MALWARE,
    name: 'Malware',
    description: 'An email has been identified as containing malware'
  },
  [Category.EMAIL__MALICIOUS_LINK]: {
    class: CategoryClass.EMAIL,
    category: Category.EMAIL__MALICIOUS_LINK,
    name: 'Malicious Link',
    description: 'A link in an email has been identified as malicious'
  },
  [Category.EMAIL__GRAYMAIL]: {
    class: CategoryClass.EMAIL,
    category: Category.EMAIL__GRAYMAIL,
    name: 'Graymail',
    description: 'An email has been identified as graymail'
  },
  [Category.IDENTITY__LOGIN]: {
    class: CategoryClass.IDENTITY,
    category: Category.IDENTITY__LOGIN,
    name: 'Login',
    description: 'A user is authenticating to your environment'
  },
  [Category.IDENTITY__DISCOVERY]: {
    class: CategoryClass.IDENTITY,
    category: Category.IDENTITY__DISCOVERY,
    name: 'Discovery',
    description: 'A user is discovering information about your environment'
  },
  [Category.IDENTITY__BRUTE_FORCE]: {
    class: CategoryClass.IDENTITY,
    category: Category.IDENTITY__BRUTE_FORCE,
    name: 'Brute Force',
    description:
      'Threat actors are unsuccessfully attempting to guess credentials in your environment'
  },
  [Category.IDENTITY__PERSISTENCE]: {
    class: CategoryClass.IDENTITY,
    category: Category.IDENTITY__PERSISTENCE,
    name: 'Persistence',
    description:
      'A process or user is attempting to persist as an identity in your environment'
  },
  // Credential Exposure group
  [Category.IDENTITY__PUBLIC_CREDENTIAL_EXPOSURE]: {
    class: CategoryClass.IDENTITY,
    category: Category.IDENTITY__PUBLIC_CREDENTIAL_EXPOSURE,
    name: 'Public Credential Exposure',
    description:
      'Credentials for a user in your environment have been discovered in a public data breach'
  },
  [Category.IDENTITY__PRIVATE_CREDENTIAL_EXPOSURE]: {
    class: CategoryClass.IDENTITY,
    category: Category.IDENTITY__PRIVATE_CREDENTIAL_EXPOSURE,
    name: 'Private Credential Exposure',
    description:
      'Credentials for a user in your environment have been phished or shared directly with an adversary'
  },

  [Category.NETWORK__NOISY]: {
    class: CategoryClass.NETWORK,
    category: Category.NETWORK__NOISY,
    name: 'Noisy',
    description:
      'A device on your network is generating or receiving a high volume of traffic'
  },
  [Category.NETWORK__INBOUND_CONNECTION]: {
    class: CategoryClass.NETWORK,
    category: Category.NETWORK__INBOUND_CONNECTION,
    name: 'Inbound Connection',
    description:
      'An inbound connection from a malicious source has been detected'
  },
  [Category.NETWORK__PHISHING]: {
    class: CategoryClass.NETWORK,
    category: Category.NETWORK__PHISHING,
    name: 'Phishing Connection',
    description: 'A connection to a known phishing service has been detected'
  },
  [Category.NETWORK__OUTBOUND_CONNECTION]: {
    class: CategoryClass.NETWORK,
    category: Category.NETWORK__OUTBOUND_CONNECTION,
    name: 'Outbound Connection',
    description:
      'An outbound network connection to a malicious destination has been detected'
  },
  [Category.NETWORK__THREAT_INDICATOR]: {
    class: CategoryClass.NETWORK,
    category: Category.NETWORK__THREAT_INDICATOR,
    name: 'Threat Indicator',
    description: 'Network communication matches a threat indicator'
  },

  [Category.DATA__DATA_TRANSFER]: {
    class: CategoryClass.DATA,
    category: Category.DATA__DATA_TRANSFER,
    name: 'Data Transfer',
    description:
      'A process or user is attempting to transfer data in an unusual manner'
  },
  [Category.DATA__DATA_SHARE]: {
    class: CategoryClass.DATA,
    category: Category.DATA__DATA_SHARE,
    name: 'Data Share',
    description:
      'A process or user is attempting to share data in an unusual manner'
  },

  // Posture group
  [Category.POSTURE__POSTURE]: {
    class: CategoryClass.POSTURE,
    category: Category.POSTURE__POSTURE,
    name: 'Posture',
    description: 'A configuration in your environment is configured insecurely'
  }
};

export type FilteredKeys<T, Prefix extends string> = {
  [K in keyof T]: K extends `${Prefix}${string}` ? K : never;
}[keyof T];

// Type to extract values of the filtered keys
export type FilteredValues<T, Prefix extends string> = T[FilteredKeys<
  T,
  Prefix
>];

export function getCategoriesByClass(categoryClass: CategoryClass): Category[] {
  return Object.values(CategoryConfig)
    .filter((v) => v.class === categoryClass)
    .map((v) => v.category);
}

export function getCategoryByRule(rule: TCategoryRule) {
  const ruleConfig = RuleConfig[rule]!;
  return CategoryConfig[ruleConfig.category]!;
}

export function getClassByCategory(category: Category) {
  return CategoryConfig[category]!.class;
}
