import { components } from '@/lib/api.types';
import { useRouteContext } from '@tanstack/react-router';
import { useMemo } from 'react';

export default function IntegrationIdentity(props: {
  identityFields: components['schemas']['IntegrationV2']['identityFields'];
  integrationPlatform: string;
}) {
  const { integrationMetadata } = useRouteContext({ from: '/_application' });
  const config = useMemo(() => {
    return integrationMetadata.find(
      (integration) => integration.slug === props.integrationPlatform
    )!;
  }, [integrationMetadata, props.integrationPlatform]);
  return (
    <div className="flex flex-col">
      {Object.entries(props.identityFields)
        .filter(([_, v]) => v != null && (v as string).length > 0)
        .map(([key, value]) => (
          <IdentityField field={key} value={value} integrationConfig={config} />
        ))}
    </div>
  );
}

export function IdentityField(props: {
  field: string;
  value: string;
  integrationConfig: components['schemas']['IntegrationMetadataConfigV2'];
}) {
  const field = props.integrationConfig?.customFields?.find(
    (field) => field.slug === props.field
  );
  if (field == null) {
    return;
  }
  return (
    <div className="text-xs">
      <span className="text-muted-foreground">{field.display}: </span>
      {props.value}
    </div>
  );
}
