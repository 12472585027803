export const DOCS = {
  CHAT_OPS: '10053554',
  CHAT_OPS_COMMUNICATION_PLAN: '10053551',
  CONTAINMENT: {
    INTRODUCTION: '10053556',
    ENDPOINT: '10053506',
    USER: '10053563'
  },
  TEAM: '10064535',
  FAQ: '10051514',
  ASSETS: {
    IP: '10053511',
    ENDPOINT: '10053500',
    USER: '10053517',
    FILE: '10053510',
    PROCESS: '10053514',
    LOCATION: '10053512'
  },
  INTEGRATIONS: {
    AWS: '10094147',
    CHECKPOINT_HARMONY: '10907060',
    CISCO_MERAKI: '10572027',
    CISCO_UMBRELLA: '10486060',
    CROWDSTRIKE_FALCON: '10503948',
    ENTRA: '10534548',
    FORTIANALYZER: '10809305',
    GOOGLE: '10051325',
    HAVE_I_BEEN_PWNED: '10053520',
    HYAS_PROTECT: '11003306',
    JAMF: '10845690',
    KANDJI: '10553419',
    MANAGE_ENGINE_AD_AUDIT_PLUS: '10715173',
    OKTA: '10053540',
    SENTINEL_ONE: '10053542',
    THINKST: '10907060'
  },
  EXCLUSIONS: '10074229',
  SERVICE_PROVIDER: '10112526'
};

export const DOC_LINKS = {
  CHAT_OPS_RECEIVED:
    'https://docs.wirespeed.co/en/articles/10053565-i-received-a-message-from-wirespeed',
  INTEGRATIONS:
    'https://docs.wirespeed.co/en/collections/10755091-integrations',
  DETECTION_REINGESTION:
    'https://docs.wirespeed.co/en/articles/10053494-cases#h_3f3c99c634',
  GOOGLE_RESET_ACCESS:
    'https://docs.wirespeed.co/en/articles/10051325-google#h_5fecc6aead',
  MICROSOFT_AADSTS650052:
    'https://docs.wirespeed.co/en/articles/10053539-microsoft#h_32c8b320b3',
  TEST_MODE: 'https://docs.wirespeed.co/en/articles/10064535-team#h_bfbd261334',
  EXCLUSIONS: 'https://docs.wirespeed.co/en/articles/10074229-exclusions',
  EXCLUSIONS_ARRAY:
    'https://docs.wirespeed.co/en/articles/10074229-exclusions#h_15f358c2a2'
};
